@import "../scss/global.scss";
@import "../scss/variables.scss";

.nav {
    background-color: $dark-grey;
    @include size(100%, 20px);
    position: fixed;
    @include flex(row, space-between, center);

    .apple {
        display: flex;
        position: relative;
        left: 1%;
    }

    .hour {
        margin-right: 10px;
    }
}
