@import "../scss/global.scss";

.container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    .skills {
        @include flex(column, center, center);

        @media screen and (max-width: 700px) {
            font-size: 14px;
        }

        img {
            @include size(25%, 40%);

            @media screen and (max-width: 700px) {
                @include size(25%, 30%);
            }
        }
    }

    @media screen and (max-width: 700px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}

h1 {
    @extend %title;

    @media screen and (max-width: 700px) {
        font-size: 20px;
    }
}

