%title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 35px;
}

@mixin flex($direction, $justify, $align) {
    display: flex;
    flex-direction: $direction;
    justify-content: $justify;
    align-items: $align;
}

@mixin size($width, $height) {
    width: $width;
    height: $height;
}

@mixin circle($background-color) {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin: 3px;
    background-color: $background-color;
}
