@import "../scss/global.scss";
@import "../scss/variables.scss";

.contact {

    .title {
        @extend %title;

        @media screen and (max-width: 700px) {
            font-size: 20px;
        }
    }

    h4 {
        padding-right: 5%;
        padding-left: 5%;
        @media screen and (max-width: 700px) {
            padding-left: 20%;
            font-size: 14px;
            padding-bottom: 0%;
        }
    }

    .wrapper {
        @include flex(row, center, center);
            form {
                @include flex(column, center, center);
                padding-top: 10px;

                @media screen and (max-width: 700px) {
                    margin-top: 0px;
                }

                input {
                    @include size(130%, 30px);
                    border: none;
                    border-radius: 5px;
                    margin: 10px 0px;
                    font-size: 14px;
                    padding-left: 10px;

                    @media screen and (max-width: 500px) {
                        @include size(70%, 20px);
                        font-size: 12px;
                    }

                    @media screen and (min-width: 500px) and (max-width: 700px) {
                        @include size(90%, 20px);
                        font-size: 12px;
                    }
                }

                div {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 110%;

                    label {
                        width: 130%;
                        font-weight: bold;

                        @media screen and (min-width: 200px) and (max-width: 700px) {
                            @include size(90%, 12px);
                            font-size: 12px;
                        } 
                    }
                }

                textarea {
                    width: 130%;
                    margin: 10px 0px;
                    font-size: 14px;
                    padding-left: 10px;
                    border-radius: 5px;
                    border: none;
                    resize: none;

                    @media screen and (max-width: 500px) {
                        @include size(70%, 90px);
                        font-size: 12px;
                    }

                    @media screen and (min-width: 500px) and (max-width: 700px) {
                        @include size(90%, 90px);
                        font-size: 12px;
                    }
                }

                .b {
                    @include flex(column, center, center);
                    button {
                        border: none;
                        padding: 10px;
                        background-color: $blue;
                        color: $white;
                        font-weight: 500;
                        cursor: pointer;
                        border-radius: 5px;

                        @media screen and (max-width: 700px) {
                            padding: 5px;
                            width: 100%;
                            font-size: 10px;
                        }
                    }
                }
            }
        

        @media screen and (max-width: 700px) {
            flex-direction: column;
        }
    }

    .info {
        padding: 20px;
            .icon {
                @include size(20%, 20%);
                margin-right: 20px;
            }
        }
}
