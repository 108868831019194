$red: #ff4044;
$yellow: #fec13d;
$green: #39c442;
$dark: #222325;
$blue: #4082f6;
$white: white;
$dark-white: #e9e9e9;
$grey: #b3b9b5;
$semi-light-grey: #d9dcd9;
$light-grey: #ddddde;
$dark-grey: #ddddde81;
$font-color: #9e9c9e;

