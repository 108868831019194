@import "./scss/variables.scss";

body {
  margin: 0;
  font-family: "Sulphur Point", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Sulphur Point";
  src: url("./fonts/SulphurPoint-Regular.ttf");
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-corner {
  width: 20px;
}

::-webkit-scrollbar-track {
  background: transparent;
  height: 80px;
}

::-webkit-scrollbar-thumb {
  background: $grey;
  border-radius: 20px;
}
