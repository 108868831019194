@import "../scss/global.scss";
@import "../scss/variables.scss";

.list {
    @include flex(row, center, center);
    @include size(100%, 11%);
    font-size: 20px;
    color: $dark;
    border-bottom: 1px solid $semi-light-grey;
    cursor: pointer;

    &.active {
        background-color: $grey;
    }

    @media screen and (max-width: 700px) {
        font-size: 15px;
        width: 100%;
    }
}

li {
    list-style: none;
}
