@import "../scss/global.scss";
@import "../scss/variables.scss";

.container {
    background-image: url("../assets/images/wallpaper.jpg");
    background-size: 100%;
    display: flex;
    height: 100vh;

    .notes {
        @include size(60%, 70%);
        margin: auto;
        border-radius: 5px 5px 5px 5px;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.274);
        background-color: $dark-white;
        color: $font-color;
        overflow: auto;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        @media screen and (max-width: 700px) {
            @include size(90%, 60%);
        }

        @media screen and (min-width: 700px) and (max-width: 1500px) {
            width: 80%;
        }

        .browser {
            height: 20px;
            background-color: $light-grey;
            @include flex(row, flex-start, center);
            border-radius: 5px 5px 0 0;
            position: fixed;
            width: 60%;
            z-index: 1;

            .circle1 {
                @include circle($red);
            }

            .circle2 {
                @include circle($yellow);
            }

            .circle3 {
                @include circle($green);
            }

            @media screen and (max-width: 700px) {
                width: 90%;
            }

            @media screen and (min-width: 700px) and (max-width: 1500px) {
                width: 80%;
            }
        }

        .left {
            float: left;
            @include size(300px, 96%);
            border-right: 1px solid $semi-light-grey;
            position: relative;
            top: 20px;

            .info {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: row;
                .imagen {
                    margin: 20px 20px;
                    width: 5%;
                    .icon {
                        @include size(250%, 250%);

                        @media screen and (max-width: 700px) {
                            margin-top: 10px;
                            @include size(20px, 20px);
                        }
                    }

                    @media screen and (max-width: 700px) {
                        margin: 0 8px;
                    }
                }

                @media screen and (max-width: 700px) {
                    padding: 4%;
                    font-size: 13px;
                }
            }

            @media screen and (max-width: 700px) {
                @include size(145px, 94%);
            }

            @media screen and (min-width: 700px) and (max-width: 770px) {
                @include size(181.25px, 95%);
            }

            @media screen and (min-width: 770px) and (max-width: 1100px) {
                @include size(230px, 95%);
            }
        }

        .right {
            @include flex(row, center, center);
            position: relative;
            top: 20px;

            .leftWrapper {
                @include flex(row, column, center);

                @media screen and (max-width: 700px) {
                    font-size: 20px;
                }
            }
        }
    }

    a {
        text-decoration: none;
        color: $font-color;
    }

    ul {
        margin: 0px;
        padding: 0px;
    }
}
