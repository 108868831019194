@import "../scss/global.scss";

.aboutMe {
    h1 {
        @extend %title;

        @media screen and (max-width: 700px) {
            font-size: 20px;
        }
    }
    .container {
        padding: 0 5%;

        h4 {
            @media screen and (max-width: 700px) {
                font-size: 14px;
            }
        }
    }
}
