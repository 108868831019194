@import "../scss/global.scss";
@import "../scss/variables.scss";

.container {
    padding: 0 100px;
    @include flex(column, center, center);
    text-align: center;

    .texts {
        h1 {
            @extend %title;

            @media screen and (max-width: 700px) {
                font-size: 20px;
            }
        }

        h4 {
            @media screen and (max-width: 700px) {
                font-size: 14px;
            }
        }

        .list {
            width: 100%;
            @include flex(column, space-between, center);
        }

        @media screen and (max-width: 700px){
            width: 100%;
        }
    }

/*     .menuProyectos {
        width: 50%;
        margin: auto;
        ul {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;
            font-weight: bold;
            li {
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                padding-right: 2%;
                padding-left: 2%;
                padding-top: 0.4%;
                padding-bottom: 0.4%;
                border-radius: 5px;

                &.active {
                    background-color: $grey;
                    color: $dark;
                    cursor: pointer;
                    transition: all .5s ease;
                }
            }
        } */
    /* } */

    @media screen and (max-width: 700px){
        padding: 10px;
    }
}