@import "../scss/global.scss";
@import "../scss/variables.scss";

.proyects {
    h2 {
        @media screen and (max-width: 700px) {
            font-size: 18px;
        }
    }
    .links {
        padding-bottom: 2%;
        margin: auto;
        .icon {
            @include size(5%, 5%);
            margin-left: 1%;
            padding-bottom: 2px;

            @media screen and (max-width: 700px) {
                width: 9%;
                margin-left: 2%;
            }
        }

        .icon2 {
            @include size(6%, 4%);
            margin-right: 1%;

            @media screen and (max-width: 700px) {
                width: 11%;
                margin-right: 2%;
            }
        }
    }
    img {
        width: 100%;
        border-radius: 5px;
    }

    h3 {
        @media screen and (max-width: 700px) {
            font-size: 17px;
        }
    }

    h4 {
        @media screen and (max-width: 700px) {
            font-size: 14px;
        }
    }
}
