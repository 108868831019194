@import "../scss/global.scss";

.container {
    overflow: visible;
    .intro {
        @extend %title;

        @media screen and (max-width: 700px) {
            font-size: 20px;
        }
    }

    .titleWrapper {
        @include flex(row, center, center);
        font-size: 20px;
         @include size(100%, 40px); 

        @media screen and (max-width: 700px) {
            font-size: 15px;
        }
    }

    img {
        display: flex;
        width: 60%;
        margin: auto;

        @media screen and (max-width: 700px) {
            width: 70%;
        }
    }
}
